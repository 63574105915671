
import { Vue, Component } from 'vue-property-decorator';
import Swiper from 'swiper';
import api from '@/api/';
@Component({
 components: {
 },
})
export default class About extends Vue {
 private article: any = {};
 private mounted() {
  /* const mySwiper = new Swiper('.swiper-container', {
    autoplay: false,
    direction: 'vertical',
    mousewheel: true,
    pagination: {
      el: '.swiper-pagination'
    }
  }) */
  // api.articleGet(1001265).then((res) => this.article = res.result)
 }
}
